// en_us.js文件
export default {
  home: {
    // 货到付款
    cashOnDelivery: 'Cash on Delivery',
    // 免费送货
    freeDelivery: 'Free Delivery',
    // 真正的保证
    theRealGuarantee: 'Genuine Guarantee',
    // 产品描述
    productDescription: 'Product description',
    // 商品详情
    productDetails: 'order details',
    // 购买按钮
    purchaseButton: 'BUY NOW'
  },
  placeOrder: {
    btnA: 'Buy ',
    btnB: 'for',
    // 数量
    quantity: 'Quantity',
    // 发货信息
    shippingInfo: 'Shipping Info',
    // 全名
    fullName: 'Full Name',
    // 请输入您的全名
    pleaseEnterFullName: 'Please enter your full name',
    // 电话号码
    phoneNumber: 'Phone Number',
    // 请输入您的电话号码
    pleaseEnterPhone: '*Please enter your phone number',
    // 完整送货地址
    fullShippingAddress: 'Full Shipping Address',
    // 尽可能详细的送货地址
    pleaseEnterAddress: 'As detailed as possible the delivery address',
    // 邮件
    email: 'Email',
    // 留下您的电子邮件以获取通知
    pleaseEnterEmail: 'Leave your email to get notification',
    // 备注
    Note: 'Note',
    // 如果有的话，写一张便条，比如交货时间
    pleaseEnterNote: 'Write a note if you have, such as delivery time',
    // 总的
    Total: 'Total',
    // 付款
    payment: 'Payment',
    // 沙币
    SAR: 'SAR',
    // 是
    yes: 'Yes',
    // 稍后
    later: 'Later',
    // 收货人
    consignee: 'consignee',
    // 订单号
    orderNumber: 'Order number',
    // 下单成功提示 您购买的产品已经下达成功,请点击YES并联系商家
    successB: 'The product you purchased has been successfully released. Please click YES and contact the merchant on WhatsApp',
    // 请选择商品规格
    pleaseChoose: 'Please select product specifications'
  },
  productList: {
    // 加载中...
    loading: 'Loading...',
    //请求失败，请稍后重试！
    fail: 'Request failed, please try again later!',
    // 下单成功！
    success: 'Order successfully!',
    // 已经是最后一页
    lastPage: 'It s already the last page',
    // 货到付款，免费送货
    promptLanguage: 'Cash on delivery, free delivery'
  }
}
