<template>
  <div id="app" style="">
    <router-view />
  </div>
</template>
<script>
import { getPixelCode } from '@/views/api/index.js'
export default {
  data() {
    return {
      faceBookCode: '790665586520453', //发送ajax请求动态的去获取像素代码，并复制给这个faceBookCode
      imgurl: '',
      scCode: '28e04b3e-e0c4-49bf-b5b6-4fbf32316ea2'
    }
  },
  created() {
    this.getPixel()
    let hostname = window.location.hostname
    hostname = hostname.replace('www.', '')
    document.title = hostname
    if (hostname.indexOf('aramoon.shop') != -1) {
      this.imgurl = 'https://www.facebook.com/tr?id=' + this.faceBookCode + '&ev=PageView&noscript=1'
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      fbq('init', this.faceBookCode)
      fbq('track', 'PageView')
    }
  },
  mounted() {},
  methods: {
    getPixel() {
      getPixelCode('/api/goods/goods/adv', '').then((res) => {
        if (res.code == 1) {
          console.log(res.data)
          // this.faceBookCode = res.data.data.faceBookCode
          if (res.data.adv.length > 0) {
            res.data.adv.forEach((element) => {
              console.log('1111111111111111fb', element)
              if (element.platform == 'fb') {
                this.faceBookCode = element.code
                this.fbMount()
              } else if (element.platform == 'sc') {
                this.scCode = element.code
                this.scMount()
              }
            })
          }
        }
      })
    },
    fbMount() {
      this.imgurl = 'https://www.facebook.com/tr?id=' + this.faceBookCode + '&ev=PageView&noscript=1'
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return
        n = f.fbq = function () {
          n.callMethod ? n.callMethod.apply(n, arguments) : n.queue.push(arguments)
        }
        if (!f._fbq) f._fbq = n
        n.push = n
        n.loaded = !0
        n.version = '2.0'
        n.queue = []
        t = b.createElement(e)
        t.async = !0
        t.src = v
        s = b.getElementsByTagName(e)[0]
        s.parentNode.insertBefore(t, s)
      })(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js')
      fbq('init', this.faceBookCode)
      fbq('track', 'PageView')
    },
    scMount() {
      ;(function (e, t, n) {
        if (e.snaptr) return
        var a = (e.snaptr = function () {
          a.handleRequest ? a.handleRequest.apply(a, arguments) : a.queue.push(arguments)
        })
        a.queue = []
        var s = 'script'
        var r = t.createElement(s)
        r.async = !0
        r.src = n
        var u = t.getElementsByTagName(s)[0]
        console.log(r, u)
        u.parentNode.insertBefore(r, u)
      })(window, document, 'https://sc-static.net/scevent.min.js')
      snaptr('init', this.scCode, {
        user_email: ''
      })
    }
  }
}
</script>
<style lang="less" scoped>
body {
  max-width: 7.5rem;
  margin: auto;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
</style>
