<template>
  <div
    ref="floatDrag"
    class="float-position"
    :style="{ left: left + 'px ', top: top + 'px', right: right + 'px', zIndex: zIndex }"
    @touchmove.prevent
    @mousemove.prevent
  >
    <div class="content">
      <van-button @click.stop="onWhatsapp" type="default" class="box-radius">
        <!-- <div> -->
        <img :src="imageUrl" alt="" class="box-radius" style="width: 50px; height: 50px" />
        <!-- <van-icon name="phone-circle-o" @click="onWhatsapp" /> -->
        <!-- </div> -->
      </van-button>
    </div>
  </div>
</template>
<script>
import { getBuriedPoint } from '@/views/home/api/index.js'
export default {
  props: {
    distanceRight: {
      type: Number,
      default: 36
    },
    distanceBottom: {
      type: Number,
      default: 80
    },
    isScrollHidden: {
      type: Boolean,
      default: false
    },
    isCanDraggable: {
      type: Boolean,
      default: true
    },
    zIndex: {
      type: Number,
      default: 50
    },
    whatsappCode: {
      type: String
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      imageUrl: require('@/assets/img/whatsappImg.webp'),
      clientWidth: null,
      clientHeight: null,
      left: null,
      top: null,
      right: null,
      timer: null,
      currentTop: null
    }
  },
  mounted() {
    console.log('resize')
    this.isCanDraggable &&
      this.$nextTick(() => {
        this.floatDrag = this.$refs.floatDrag
        // 获取元素位置属性
        this.floatDragDom = this.floatDrag.getBoundingClientRect()
        // 设置初始位置
        // this.left = this.clientWidth - this.floatDragDom.width - this.distanceRight;
        this.right = 0
        this.top = this.clientHeight - this.floatDragDom.height - this.distanceBottom
        this.initDraggable()
      })
    // this.isScrollHidden && window.addEventListener('scroll', this.handleScroll);
    window.addEventListener('resize', this.handleResize)
  },
  created() {
    this.clientWidth = document.documentElement.clientWidth
    this.clientHeight = document.documentElement.clientHeight
  },
  BeforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    // 链接onWhatsapp
    onWhatsapp() {
      let data = {
        action: 'whatsapp',
        url: localStorage.getItem('url') ? localStorage.getItem('url') : ''
      }
      getBuriedPoint('/api/order/order/record', data)
      let url = localStorage.getItem('url')
      window.location.href = `https://api.whatsapp.com/send?phone=` + this.whatsappCode + '&text=link:%20%20' + url + '%20'
    },
    /**
     * 窗口resize监听
     */
    handleResize() {
      this.clientWidth = document.documentElement.clientWidth
      this.clientHeight = document.documentElement.clientHeight
      this.checkDraggablePosition()
    },
    /**
     * 初始化draggable
     */
    initDraggable() {
      this.floatDrag.addEventListener('touchstart', this.toucheStart)
      this.floatDrag.addEventListener('touchmove', (e) => this.touchMove(e))
      this.floatDrag.addEventListener('touchend', this.touchEnd)
    },

    toucheStart() {
      this.canClick = false
      this.floatDrag.style.transition = 'none'
    },
    touchMove(e) {
      this.canClick = true
      if (e.targetTouches.length === 1) {
        // 单指拖动
        let touch = event.targetTouches[0]
        this.left = touch.clientX - this.floatDragDom.width / 2
        this.top = touch.clientY - this.floatDragDom.height / 2
      }
    },
    touchEnd() {
      if (!this.canClick) return // 解决点击事件和touch事件冲突的问题
      this.floatDrag.style.transition = 'all 0.3s'
      this.checkDraggablePosition()
    },
    /**
     * 判断元素显示位置
     * 在窗口改变和move end时调用
     */
    checkDraggablePosition() {
      this.clientWidth = document.documentElement.clientWidth
      this.clientHeight = document.documentElement.clientHeight
      if (this.left + this.floatDragDom.width / 2 >= this.clientWidth / 2) {
        // 判断位置是往左往右滑动
        this.left = this.clientWidth - this.floatDragDom.width
      } else {
        this.left = 0
      }
      if (this.top < 0) {
        // 判断是否超出屏幕上沿
        this.top = 0
      }
      if (this.top + this.floatDragDom.height >= this.clientHeight) {
        // 判断是否超出屏幕下沿
        this.top = this.clientHeight - this.floatDragDom.height
      }
    }
  }
}
</script>
<style lang="less" scoped>
.float-position {
  width: 52px;
  position: fixed;
  right: 0;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  .content {
    position: relative;
    display: flex;
    align-content: center;
    justify-content: center;
    .box-radius {
      border-radius: 50%;
      border: 0;
      height: 1rem;
      width: 1rem;
      font-size: 0.6rem;
      color: #fff;
    }
    .van-button__content {
      height: auto;
    }
  }

  .close {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    position: absolute;
    right: 30px;
    top: -12px;
    cursor: pointer;
  }
}

.cart {
  border-radius: 50%;
  width: 5em;
  height: 5em;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header-notice {
  display: inline-block;
  transition: all 0.3s;

  span {
    vertical-align: initial;
  }

  .notice-badge {
    color: inherit;

    .header-notice-icon {
      font-size: 16px;
      padding: 4px;
    }
  }
}

.drag-ball .drag-content {
  overflow-wrap: break-word;
  font-size: 14px;
  color: #fff;
  letter-spacing: 2px;
}
</style>
